html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}
body {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: #fff !important;
  color: inherit !important;
}

#root {
  height: 100%;
}

.loading {
  position: relative;
}
.loading:after {
  content: '';
  position: absolute;
  left: -5px;
  top: -5px;
  right: -5px;
  bottom: -5px;
  z-index: 9999;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.6) url(assets/images/loader.svg) no-repeat 50% 50%;
}

.date-time {
  color: #999;
}
.date-time b {
  font-weight: normal;
  color: #000;
  display: inline-flex;
  margin-right: 0.5em;
}
